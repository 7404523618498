import React from 'react'
import ManagerDashboard from '../../components/Manager/ManagerDashboard'
import MyTasksCard from '../../components/Manager/MyTasksCard'
import TeamMembersCard from '../../components/Manager/TeamMembersCard'
import RecognitionCardContainer from '../../components/Manager/RecognitionCardContainer'

function Manager() {
  return (
    <div style={{maxWidth:"1246px",padding:"25px",margin:"auto",width:"100%"}}>
      <ManagerDashboard></ManagerDashboard>
      <MyTasksCard></MyTasksCard>
      <TeamMembersCard></TeamMembersCard>
      <RecognitionCardContainer></RecognitionCardContainer>
    </div>
  )
}

export default Manager
